export const SITE_URL = process.env.GATSBY_SITE_URL

export const POETRI_APIKEY = process.env.GATSBY_POETRI_APIKEY
export const POETRI_REST_COUNTRIES = process.env.GATSBY_POETRI_REST_COUNTRIES

export const APP_INTERCOM = process.env.GATSBY_APP_INTERCOM

export const HUBSPOT_EMAIL_TEST = process.env.GATSBY_HUBSPOT_EMAIL_TEST

export const URL_BLOG = process.env.GATSBY_URL_BLOG
export const ACTIVE_ENV = process.env.GATSBY_ACTIVE_ENV